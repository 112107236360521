@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.active{
    background-color: #0057CE;
    color: #F58220;
    padding-top: 10px;
    padding-bottom: 10px;
}